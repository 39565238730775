import {  Modal } from 'react-bootstrap';
import styled from "styled-components";
const BoldFont = 'Rubik-Bold';

const StyledModal = styled(Modal)`
	.modal-dialog{
		display: flex;
    justify-content: center;
    transform: ${({transform}) => transform && 'translate(0, 80px) translate(0, -90%) !important'};
		.modal-content{
      width: ${({newWidth}) => newWidth ? newWidth : '100%'};
	  height:${({newHeight}) => newHeight ? newHeight : 'auto'};
	  box-shadow: none;
	  position: absolute;
	  border-radius: 10px;
			.modal-header {
				border-bottom: ${({noBorder}) => noBorder ? noBorder : '1px solid #e5e5e5'};
                background: linear-gradient(0deg, #8AD3F7 0%, #52A8ED 100%);
				border-radius: 6px;
				> div {
					display: flex;
					justify-content: center;

					> p{
						font-size: 20px;
						font-family: ${BoldFont};
						margin-top: 10px 0 0 0;
					}
				}
				button{
					display: none;
				}
			}
			.modal-header .close {
				margin-top: -2px;
				outline: none;
				display: none;		
			}
			.cross_img {
				float: right;
				> img {
					width: 20px;
					height: 20px;
					cursor: pointer;
				}
			}
		}
	}
  @media (max-width: 500px) and (min-width: 320px) {
    .modal-dialog {
			width: 100%;

			.modal-content{
				width: 90%;
				margin-left: -6%;
			}
    }
  }
`;

const StyledTitle = styled(Modal.Title)`
font-family: Rubik;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 24px;
text-align: left;
color: #FFFFFF;
`;

const StyledBody = styled(Modal.Body)`
margin-right: -39px;
margin-left: -8px;
height: 100%;
`;

export { StyledModal,StyledTitle,StyledBody};