import React from 'react'
import { Modal } from 'react-bootstrap'; 
import PropTypes from 'prop-types';
import {StyledModal,StyledTitle,StyledBody, CompayContainer, CompanyButton} from './styles';
import { connect } from "react-redux";
import { ImageUrl } from '../../../utils/constants';
import { CalendarContainer, ChallengeButton } from '../styles';
import RadioButton from "../../CustomRadioButton/index";
import { ActivityDropdown, CustomMenuItem, DropDownTitleContainer, DropdownArrow, FieldTitle } from '../../Recognition/AddCoreValues/styles';
import moment from 'moment';
import { Calendar } from 'react-calendar';

class CompanyInitiative extends React.Component {
      
  showWellnessInitiatives = (companyInitiatives) => (
    companyInitiatives && companyInitiatives.length && companyInitiatives.map((data,id) => (
      <CompayContainer key={id}>
        <CompanyButton>
          <div className='image_cls'>
            <img src={`${ImageUrl}/${data.initiative_image}`} />
          </div>
          <div className="name">{data.initiative_name}</div>
          <div className='new_image'>
          </div>
        </CompanyButton>
      </CompayContainer>
    ))
  );
  

  render() {
    const { showModal,onClose,selectedInitiative,value,singleCompanyPopUp,featureNewData,step,onNext,onBack,toggleRadioButton,selectedOption,launchDate,onChange,calendarStatus,toggleCalendar, isEdit} = this.props;
    const today = new Date()
    // const todayDate = moment().format('MMM DD, YYYY');
    const currentYear = today.getFullYear();
    const firstDayNextYear = new Date(currentYear + 1, 0, 1);
    const lastDayCurrentYear = new Date(firstDayNextYear - 1);
    return (
      <StyledModal transform={1} show={showModal}   newWidth={"88.7%"}  newBg={'newBg'} >
        <Modal.Header closeButton onHide={() => onClose()} border={'border'} style={{background:'#005c87'}}>
          <StyledTitle style={{color: '#fff', display: 'flex', width: '100%', justifyContent: 'center'}} color={true} textAlign={'textAlign'}>Company Initiatives</StyledTitle>
          <div className='cross_img' style={{float: 'right' ,cursor: "pointer" }}>
            <img src="/public/images/AdminEngagement/Cross_Icon_Img.png" onClick={() => onClose()} style={{width: "20px", height: "20px",  cursor: "pointer"}}/>
          </div>
        </Modal.Header>
        <StyledBody>
          {step === 1 ? 
            <React.Fragment>
              <StyledTitle color={'color'} paddingBottom={'paddingBottom'}>Company Initiative</StyledTitle>
              {this.showWellnessInitiatives([selectedInitiative])}
              <StyledTitle color={'color'}  marginBottom={'10px'} paddingBottom={true}>Select your point value</StyledTitle>
              <DropDownTitleContainer widthValue="80%" height="auto" padding="0px 25px 25px 25px" float="none">
                <FieldTitle>
                  {""}
                </FieldTitle>
                <DropdownArrow
                  alt="image"
                  top={this.props.arrowStatus ? "25px" : "20px"}
                  right="40px"
                  src={this.props.arrowStatus ? ImageUrl+"/images/AdminEngagement/teamDownArrow.svg" : "/public/images/AdminEngagement/newVector_img.svg"}
                />
                <ActivityDropdown
                  title={value}
                  notActive={false}
                  id="dropdown-recurring"
                  onClick={this.props.handleArrow}
                  onSelect={(index) => this.props.onChangeDropdown(featureNewData[index])}
                >
                  {featureNewData && featureNewData.map((list, index) => (
                    <CustomMenuItem      
                      key={list.id}
                      eventKey={index}
                      onSelect={() => this.props.onChangeDropdown(list)}
                      active={value === list.value}
                    >
                      {list.key} <img className='tick-img' alt="profilre-img" src={'/public/images/ChallengeDetailsScreen/completeCheck.png'}></img>
                    </CustomMenuItem>
                  ))}
                </ActivityDropdown>
              </DropDownTitleContainer>
            </React.Fragment>
            : <div>
              <p style={{marginLeft:'25px',fontFamily:'rubik',fontSize:'16px',color:"#005c87"}}>When would you like to schedule your <span style={{fontFamily:'rubik-medium'}}>{`"${selectedInitiative.initiative_name}"`}</span></p>
              <div onClick={() => !selectedInitiative.is_upcoming && toggleRadioButton(1)} style={{margin:'15px 0px 5px 25px'}}>
                <RadioButton
                  id="1"
                  handler={toggleRadioButton}
                  value={1}
                  isChecked={selectedOption === 1}
                  label={"Schedule Now"}
                  challengeCreate={true}
                  disabled={selectedInitiative.is_upcoming}
                />
              </div>
              <div onClick={() => toggleRadioButton(2)} style={{marginLeft:'25px'}}>
                <RadioButton
                  id="2"
                  handler={toggleRadioButton}
                  value={2}
                  isChecked={selectedOption === 2}
                  label={'Schedule for Later'}
                  challengeCreate={true}
                  style={{color:"#005c87"}}
                />
              </div>
              {selectedOption === 0 && <div style={{width:'100%',height:'150px'}}></div>}
              {selectedOption === 1 ? <div style={{margin:"40px 25px",display:'flex',justifyContent:'space-between',alignItems:'center',color:"#005c87",fontSize:'16px'}}>
                <p>Launch Date <span style={{color:'#f4aaa9'}}>*</span></p>
                <p style={{width:'271px',height:'60px',background:"#e6eff3",border:'1px solid #a1c4d3',borderRadius:'6px',color:"#5c97b2",display:'flex',justifyContent:'center',alignItems:'center',fontFamily:"rubik-medium"}}>{moment(launchDate).format('MMM DD, YYYY')}</p>
              </div>: selectedOption === 2 ? <div style={{position:'relative'}}>
                <div style={{margin:"40px 25px",display:'flex',justifyContent:'space-between',alignItems:'center',color:"#005c87",fontSize:'16px'}}>
                  <p>Launch Date <span style={{color:'#f4aaa9'}}>*</span></p>
                  <p style={{ width: '271px', height: '60px', background: "#f7f5eb", border: '1px solid #a1c4d3', borderRadius: '6px', color: "#5c97b2", display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontFamily: "rubik-medium", padding: '0px 20px', cursor: 'pointer' }} onClick={toggleCalendar}>
                    <span>
                      {/* {moment(launchDate).isSame(todayDate, 'day')
                        ? "Select Start Date": moment(launchDate).format('MMM DD, YYYY')} */}
                      {moment(launchDate).format('MMM DD, YYYY')}
                    </span>
                    <img src="/public/images/calendar.png" alt="" style={{ width: '21px', height: '21px' }} />
                  </p>
                </div>
                {calendarStatus ? <CalendarContainer>
                  <p style={{margin:'15px 0px 6px 15px',color:'#005c87',fontFamily:"Rubik",fontSize:"14px",fontWeight: 400,lineHeight:"20px",textTransform: "uppercase"}}>Month of the Year {moment().year()}</p>
                  <Calendar
                    onChange={(value,event) => onChange(value,event)}
                    value={launchDate}
                    minDate={today}
                    maxDate={lastDayCurrentYear}
                  />
                </CalendarContainer>:null}
              </div> : null}
            </div>
          }
          <div style={{width:'100%',border:"1px solid #b3cfdb"}}/>
          <ChallengeButton width={'auto'} disabled={(step === 1 && value === "Select Points") || (step === 2 && launchDate === "") || (step === 2 && !selectedOption)}  onClick={(step === 1 && value !== "Select Points") ? onNext : (step === 2 && launchDate !== " ") ? singleCompanyPopUp : ""}>
            {step === 1 ? "Next" : isEdit ? "Update Initiative" :"Launch Initiative" }
          </ChallengeButton>
          {step === 2 && <p onClick={onBack} style={{width:'100%',color:'#005c87',margin:'18px 0px 0px 0px',fontFamily:'rubik',fontSize:"16px",textDecoration:'underline',textAlign:'center',cursor:"pointer"}}>{"<< Go Back"}</p>}
        </StyledBody>
      </StyledModal>    
    ) ;
  }

}  
CompanyInitiative.propTypes = {
  showModal: PropTypes.bool.isRequired,
  singleCompanyPopUp: PropTypes.func,
  thankYouPopup: PropTypes.func,
  onClose: PropTypes.func,
  history: PropTypes.object,
  wellnessInitiatives: PropTypes.array,
  selectedInitiative:  PropTypes.array,
  companyInitiatives: PropTypes.array,
  value:  PropTypes.string,
  featureNewData:  PropTypes.object,
  onChangeDropdown:  PropTypes.func,
  handleArrow: PropTypes.func,
  arrowStatus: PropTypes.bool,
  step: PropTypes.number,
  status: PropTypes.number,
  launchDate: PropTypes.any,
  onNext: PropTypes.func,
  onBack:PropTypes.func,
  toggleRadioButton:PropTypes.func,
  selectedOption:PropTypes.number,
  onChange:PropTypes.func,
  toggleCalendar:PropTypes.func,
  calendarStatus:PropTypes.number,
  isEdit:PropTypes.bool,
}

const mapStateToProps = (state) => ({
  wellnessInitiatives: state.companyDashboard.wellnessInitiatives,
  companyInitiatives: state.companyDashboard.companyInitiatives  
});

export default connect(mapStateToProps, null)(CompanyInitiative);